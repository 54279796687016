@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(//db.onlinewebfonts.com/c/23c0fcab84d99da0de762de7e220a6e1?family=Europa);
@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@1,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Styles for the Home React component */
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-image {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(/static/media/rads1.1d6aae2c.jpg);
  background-position: center;
}

.middle {
  width: 75%;
  height: 100%;
  margin-top: 6%;
  background-color: #c9c9c9;
}

.title {
  font-size: 58px;
  font-weight: 900;
  color: #111a29;
}

.subtitle {
  margin-top: -20px;
  font-size: 28px;
  font-weight: 700;
  color: #111a29;
}

.hero-text {
  display: table;
  margin: 0 auto;
  margin-top: 20px;
}

.home__button-link {
  text-decoration: none;
}

.MuiButton-root.__button {
  width: 200px;
  height: 40px;
  font-size: 18px;
  font-weight: 700;
  color: #111a29;
}

/* Increase specificity to override component styles */
.MuiButton-root.__button:hover {
  background-color: transparent;
  border-bottom: 4px #111a29 solid;
  border-radius: 0;
}

.menunavi {
  display: none;
}

@media screen and (max-width: 849px) {
  .main-menu {
    display: none;
  }
  .menunavi {
    cursor: pointer;
    display: block;
    z-index: 200000;
    margin-top: -60px;
    position: relative;
  }

  #menuu {
    float: none;
    display: block;
    width: 100%;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    background-color: #111a29;
    margin-top: 2%;
    padding-top: 1%;
    height: 43.5px;
    text-align: center;
    color: #e2d0c7;
    text-align: center;
    vertical-align: middle !important;
  }

  .menubar {
    margin-top: 0px;
  }

  .MuiButton-root.button{
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
    color: #e2d0c7;
    background-color: #111a29;
    border-radius: 0;
  }

}

/* Styles for the Header React component */
.header {
  margin-top: 2%;
  margin-bottom: 2%;
  height: 50px;
  text-align: center;
  background: #e2d0c7;
  vertical-align: middle;
}

.home______button, .button {
	text-decoration: none;
	padding-top: 11.5px;
	padding-bottom: 11px; 
	font-size: 18px;
	font-weight: 500;
	color: #111a29;
  text-align: center;
  border-radius: 20px;
  width: 200px;
  display: inline-block;
    
}

.menunav {
  display: none;
}

.home______button:hover, .button.active, .button:hover {
	background-color: #111A29;
	color: #e2d0c7;
	border-radius: 0;
}

@media screen and (max-width: 999px) {
  .header {
    display: none;
  }
  .menunav {
    cursor: pointer;
    display: block;
    z-index: 200000;
    position: relative;
  }

  #menu {
    float: none;
    display: block;
    width: 100%;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    background-color: #111a29;
    margin-top: 2%;
    padding-top: 1%;
    height: 43.5px;
    text-align: center;
    color: #e2d0c7;
    text-align: center;
    vertical-align: middle !important;
  }

  .home______button{
    border-top: 3px solid #111a29;
  }
  .home______button, .button{
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
    background: #e2d0c7;
    border-radius: 0;
  }

}


/* Styles for the Home React component */
body {
  background-color: white;
}

@font-face {
  font-family: myFirstFont;
  src: url(/static/media/AmsterdamFourTtf-owMx4.64154e44.ttf);
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centre {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section {
  position: absolute;
  left: 35%;
}

.about-me {
  color: #111a29;
  text-align: justify;
  width: 80%;
  margin-left: 5%;
  margin-top: 5%;
  font-size: 18px;
  font-weight: 300;
}

.section-title {
  color: #111a29;
  font-size: 46px;
  font-family: myFirstFont;
  font-weight: 200;
}

.img-placement {
  left: 10%;
  width: 25%;
  height: 81%;
  position: absolute;
  background-image: url(/static/media/me2.3893770e.png);
  background-size: cover;
  background-position: center;

}

.icon {
  width: 8%;
  vertical-align: bottom;
  text-align: center;
  margin-right: -3.5%;
}

.resume-btn {
  padding: 10px;
  background-color: #111a29;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  margin-left: -13.5%;
}

/* Darker background on mouse-over */
.resume-btn:hover {
  background-color: #202836;
  color: white;
}
input[type=text], input[type=email], textarea, select {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 5px;
  display: inline-block;
  border: 3px solid #111a29;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: sans-serif;
}

input[type=submit] {
  width: 30%;
  background-color: #111a29;
  border: 2px white solid;
  color: white;
  padding: 14px 20px;
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

input[type=submit]:hover {
  background-color: #202836;
  color: white;
}

label {
  color: #111a29;
  font-size: 18px;
  font-weight: 600;
}

form {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;
}

.section-title{
  margin-left: 3%;
  margin-top: 5%;
}

.section2 {
  margin-right: 40%;
}

.section1 {
  right: 16%;
  width: 21%;
  height: 78%;
  position: absolute;
  background-image: url(/static/media/me.3ad81fca.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.section3 {
  right: 9%;
  width: 5%;
  position: absolute;
}

.fa {
  padding: 20px;
  margin-top: 5px;
  text-align: center;
  border-radius: 50%;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
  width: 55px;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-instagram {
  background: #125688;
  color: white;
}

.fa-github {
  color: white;
  background: #211F1F;
}
.projects{
	padding: 50px;
}

.demo-btn {
	background-color: #cfa197
}
body{
	overflow-y: scroll;
}

.quote {
	font-family: 'EB Garamond', serif;
	color: black;
	font-style: italic;
	font-size: 32px;
	text-align: center;
}

.author {
	font-family: Europa;
	color: black;
	font-size: 20px;
}

.effect {
	font-size: 38px;
	font-style: bold;
}

.quotation-div {
	margin-top: 5%;
}

.line {
	border: 0.15px solid #dcb7bb;
}

