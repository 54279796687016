/* Styles for the Header React component */
.header {
  margin-top: 2%;
  margin-bottom: 2%;
  height: 50px;
  text-align: center;
  background: #e2d0c7;
  vertical-align: middle;
}

.home______button, .button {
	text-decoration: none;
	padding-top: 11.5px;
	padding-bottom: 11px; 
	font-size: 18px;
	font-weight: 500;
	color: #111a29;
  text-align: center;
  border-radius: 20px;
  width: 200px;
  display: inline-block;
    
}

.menunav {
  display: none;
}

.home______button:hover, .button.active, .button:hover {
	background-color: #111A29;
	color: #e2d0c7;
	border-radius: 0;
}

@media screen and (max-width: 999px) {
  .header {
    display: none;
  }
  .menunav {
    cursor: pointer;
    display: block;
    z-index: 200000;
    position: relative;
  }

  #menu {
    float: none;
    display: block;
    width: 100%;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    background-color: #111a29;
    margin-top: 2%;
    padding-top: 1%;
    height: 43.5px;
    text-align: center;
    color: #e2d0c7;
    text-align: center;
    vertical-align: middle !important;
  }

  .home______button{
    border-top: 3px solid #111a29;
  }
  .home______button, .button{
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
    background: #e2d0c7;
    border-radius: 0;
  }

}

