@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

input[type=text], input[type=email], textarea, select {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 5px;
  display: inline-block;
  border: 3px solid #111a29;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: sans-serif;
}

input[type=submit] {
  width: 30%;
  background-color: #111a29;
  border: 2px white solid;
  color: white;
  padding: 14px 20px;
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

input[type=submit]:hover {
  background-color: #202836;
  color: white;
}

label {
  color: #111a29;
  font-size: 18px;
  font-weight: 600;
}

form {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;
}

.section-title{
  margin-left: 3%;
  margin-top: 5%;
}

.section2 {
  margin-right: 40%;
}

.section1 {
  right: 16%;
  width: 21%;
  height: 78%;
  position: absolute;
  background-image: url("./static/me.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.section3 {
  right: 9%;
  width: 5%;
  position: absolute;
}

.fa {
  padding: 20px;
  margin-top: 5px;
  text-align: center;
  border-radius: 50%;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
  width: 55px;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-instagram {
  background: #125688;
  color: white;
}

.fa-github {
  color: white;
  background: #211F1F;
}